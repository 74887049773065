import React, { useEffect } from 'react';

const TermsOfCondition = () => {
  useEffect(() => {
    // Load the external script
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const htmlContent = `
    <!DOCTYPE html>
    <html>
    <head>
      <meta charset='utf-8'>
      <meta name='viewport' content='width=device-width'>
      <title>Terms &amp; Conditions</title>
      <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; } </style>
    </head>
    <body>
      <div name="termly-embed" data-id="05ac77cf-a582-490f-b272-6f17392ed5e1"></div>
    </body>
    </html>
  `;

  return (
    <div className='py-[20px] px-[40px] max_sm:px-[20px]' dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default TermsOfCondition;
