import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="py-[20px] px-[40px] max_sm:px-[20px]">
      <div name="termly-embed" data-id="14127da5-6d5e-4f7a-b6f3-a3125833a4ad"></div>
    </div>
  );
};

export default PrivacyPolicy;
