import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfCondition from './Pages/TermsOfCondition';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfCondition />} />
      </Routes>
    </>
  );
};

export default App;
